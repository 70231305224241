import React from 'react'
import { graphql } from 'gatsby'
import cx from 'classnames'

import get from 'lodash/get'

import Container from '../components/container'
import Layout from '../components/layout'

function Terms({ data, location }) {
  const content = get(data, 'contentfulTerms.body.childMarkdownRemark.html')
  const title = get(data, 'contentfulTerms.title')
  return (
    <Layout className="scroll-smooth" location={location}>
      <Container>
        <div className={cx('w-full mx-auto prose lg:w-2/3')}>
          <h1 className="text-2xl underline font-bold">{title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default Terms

// query for
export const pageQuery = graphql`
  query TermsQuery($language: String!, $locale: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile {
      nodes {
        relativePath
      }
    }

    contentfulTerms(
      title: { eq: "Terms of Service" }
      node_locale: { eq: $locale }
    ) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
